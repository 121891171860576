import Services from "../../../services";

class KegiatanAnggaranApi {
  get() {
    return Services.get("/ang/kegiatan");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/ang/kegiatan/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  getKode(value) {
    return Services.get("/ang/kegiatan/no_baru?tanggal=" + value);
  }

  getKaryawan() {
    return Services.get("/ang/karyawan");
  }

  getCOA() {
    return Services.get("/ang/karyawan");
  }

  getForm(id) {
    return Services.get("/ang/kegiatan/form/?id_program=" + id);
  }

  getFormEdit(id_program, id_kegiatan) {
    return Services.get(
      `/ang/kegiatan/form/?id_program=${id_program}&id_kegiatan=${id_kegiatan}`
    );
  }
  create(value) {
    return Services.post("/ang/kegiatan/form", value);
  }
  update(value) {
    return Services.post("/ang/kegiatan/form", value);
  }
  delete(id) {
    return Services.post("/ang/kegiatan/delete", id);
  }
}

export default new KegiatanAnggaranApi();
