import React, { useState, useEffect } from "react";
import { Card, Container, Col, Row, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import {
  Alert,
  BackButton,
  CreateButton,
  CRUDLayout,
  Input,
  Select,
  CreateModal,
  DeleteModal,
  ActionButton,
  DeleteButton,
  DataStatus,
  SelectSearch,
  SelectMonth,
  Table,
  DatePicker,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../components";
import TextArea from "../../../../components/TextArea/TextArea";
// import { IoSaveOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { KategoriAnggaranApi, KegiatanAnggaranApi, COAApi } from "../../../../api";
import { RupiahConvert, DateConvert } from "../../../../utilities";
import { IoSaveOutline } from "react-icons/io5";
// import { ReadModal } from "../../../../../../fe_itdc_hrda/src/components";

const AddKegiatan = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false);
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  //state data
  const [data, setData] = useState([]);
  const [kode, setKode] = useState("");
  const [dataKategoriSumberDaya, setDataKategoriSumberDaya] = useState([]);
  const [dataSumberDaya, setDataSumberDaya] = useState([]);
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [dataCOA, setDataCOA] = useState([]);
  // console.log("kode", kode);

  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [createSumberDayaAlertConfig, setCreateSumberDayaAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: 'tes'
  })
  const [showAlert, setShowAlert] = useState(false);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalSelesai, setTanggalSelesai] = useState("");

  const { id } = useParams();
  const history = useHistory();

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    // request data ke server
    Axios.all([
      KegiatanAnggaranApi.getForm(id),
      KategoriAnggaranApi.get(),
      KegiatanAnggaranApi.getKaryawan(),
      COAApi.get(),
    ])
      .then(
        Axios.spread((KegiatanAnggaran, KatgeoriAnggaran, Karyawan, Coa) => {
          setData(KegiatanAnggaran.data.data);
          setDataKategoriSumberDaya(KatgeoriAnggaran.data.data);
          setDataKaryawan(Karyawan?.data?.data ?? []);
          setDataCOA(Coa?.data?.data ?? []);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Tambah Kegiatan");

    // jalankan function request data ke server
    getData();
  }, [setNavbarTitle]);

  // Modal Hapus
  const HapusModal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    const deleteDataHandler = () => {
      setBtnLoading(true);
      dataSumberDaya.splice(deleteData._rowindex, 1);

      setIsDeleteData(false);
      setShowAlert(true);
      setAlertConfig({
        variant: "primary",
        text: "Hapus data berhasil!",
      });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      >
        Nama sumber daya: {deleteData.item_sumber_daya}
      </DeleteModal>
    );
  };

  // Modal Tambah
  const TambahModal = () => {
    // nilai awal form
    const formInitialValues = {
      id_kategori_sumber_daya: "",
      id_coa: "",
      nama_akun: "",
      nomor_akun: "",
      nama_kategori_sumber_daya: "",
      item_sumber_daya: "",
      qty: "",
      satuan: "",
      harga_satuan: "",
    };
    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      id_kategori_sumber_daya: Yup.string().required("Masukan kategori sumber daya"),
      id_coa: Yup.string().required("Pilih tipe anggaran"),
      item_sumber_daya: Yup.string().required("Masukan item sumber daya"),
      qty: Yup.string().required("Masukan qty"),
      satuan: Yup.string().required("Masukan satuan"),
      harga_satuan: Yup.string().required("Masukan harga satuan"),
    });

    const formSubmitHandler = (event) => {
      dataSumberDaya.push(event);
      setDataSumberDaya(dataSumberDaya);
      setIsCreateForm(false);
      setCreateSumberDayaAlertConfig({
        show: true,
        variant: 'primary',
        text: 'Sumber daya berhasil ditambah!'
      })
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title="Sumber Daya">
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Kategori Sumber Daya"
                  type="text"
                  name="id_kategori_sumber_daya"
                  placeholder="Pilih kategori sumber daya"
                  option={dataKategoriSumberDaya.map(val => {
                    return {
                      label: val.nama_kategori_sumber_daya,
                      value: val.id_kategori_sumber_daya
                    }
                  })}
                  onChange={(e) => {
                    let label = e.label;
                    let value = e.value;
                    setFieldValue("id_kategori_sumber_daya", value);
                    setFieldValue("nama_kategori_sumber_daya", label);
                  }}
                  error={errors.id_kategori_sumber_daya && touched.id_kategori_sumber_daya && true}
                  errorText={errors.id_kategori_sumber_daya && touched.id_kategori_sumber_daya && errors.id_kategori_sumber_daya}
                />
                <Input
                  label="Item Sumber Daya"
                  type="text"
                  name="item_sumber_daya"
                  placeholder="Masukan item sumber daya"
                  value={values.item_sumber_daya}
                  onChange={handleChange}
                  error={errors.item_sumber_daya && touched.item_sumber_daya && true}
                  errorText={errors.item_sumber_daya}
                />
                <Input
                  label="Satuan"
                  type="text"
                  name="satuan"
                  placeholder="Masukan satuan"
                  value={values.satuan}
                  onChange={handleChange}
                  error={errors.satuan && touched.satuan && true}
                  errorText={errors.satuan}
                />
                <Row>
                  <div className="col-10">
                    <SelectSearch
                      label="Tipe Anggaran"
                      name="id_coa"
                      placeholder="Pilih tipe anggaran"
                      option={
                        dataCOA
                          ? dataCOA
                            .filter((val) => val.normal_pos === "D")
                            .map((val) => {
                              return {
                                label: `${val.nomor_akun} - ${val.nama_akun}`,
                                value: val.id_coa,
                                normal_pos: val.normal_pos,
                                nomor_akun: val.nomor_akun,
                              };
                            })
                          : {
                            value: "",
                            label: "Pilih tipe anggaran",
                          }
                      }
                      onChange={(val) => {
                        setFieldValue("id_coa", val.value);
                        setFieldValue("nama_akun", val.label);
                        setFieldValue("normal_pos", val.normal_pos);
                        setFieldValue("nomor_akun", val.nomor_akun);
                      }}
                      error={errors.id_coa && touched.id_coa && true}
                      errorText={errors.id_coa && touched.id_coa && errors.id_coa}
                    />
                  </div>
                  <Col>
                    <Input
                      label="Pos"
                      readOnly={true}
                      value={values.normal_pos ? values.normal_pos : "-"}
                    />
                  </Col>
                </Row>
                <Input
                  label="Qty"
                  type="text"
                  name="qty"
                  placeholder="Masukan Qty"
                  value={values.qty}
                  onChange={handleChange}
                  error={errors.qty && touched.qty && true}
                  errorText={errors.qty}
                />
                <Input
                  label="Harga Satuan"
                  type="text"
                  name="harga_satuan"
                  placeholder="Masukan harga satuan"
                  value={RupiahConvert(values.harga_satuan.toString()).detail}
                  onChange={e => {
                    const val = e.target.value
                    const convert = RupiahConvert(val.toString()).default
                    setFieldValue('harga_satuan', Number.isInteger(convert) ? convert : "")
                  }}
                  error={errors.harga_satuan && touched.harga_satuan && true}
                  errorText={errors.harga_satuan}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };
  // nilai awal form
  const formInitialValues = {
    id_program: data?.program?.id_program,
    tgl_input: "",
    no_kegiatan: "",
    nama_kegiatan: dataForm.nama_kegiatan,
    id_karyawan: dataForm.id_karyawan,
    deskripsi_kegiatan: dataForm.deskripsi_kegiatan,
    tgl_mulai: dataForm.tgl_mulai,
    tgl_selesai: dataForm.tgl_selesai,
  };

  // skema validasi form
  const formValidationSchema = Yup.object().shape({
    tgl_input: Yup.string().required("Masukan tanggal"),
    no_kegiatan: Yup.string().required("Masukan no kegiatan"),
    nama_kegiatan: Yup.string().required("Masukan nama kegiatan"),
    id_karyawan: Yup.string().required("Masukan nama karyawan"),
    tgl_mulai: Yup.string().required("Masukan tanggal mulai"),
    tgl_selesai: Yup.string().required("Masukan tanggal selesai"),
  });

  const getNoKegiatan = (value, setFieldValue) => {
    setIsNomorLoading(true);

    KegiatanAnggaranApi.getKode(value)
      .then(({ data }) => {
        setKode(data.data);
        setFieldValue("no_kegiatan", data.data);
      })
      .catch((err) => alert("Gagal mengambil nomor realisasi"))
      .finally(() => setIsNomorLoading(false));
  };

  const formSubmitedHandler = (values, { setSubmitting }) => {
    const filterSumberDaya = []

    dataSumberDaya.map(val => filterSumberDaya.push({
      id_coa: val.id_coa,
      id_kategori_sumber_daya: val.id_kategori_sumber_daya,
      item_sumber_daya: val.item_sumber_daya,
      qty: val.qty,
      satuan: val.satuan,
      harga_satuan: val.harga_satuan
    }))

    let finalValues = {
      ...values,
      sumber_daya: filterSumberDaya,
      bulan_tahun: data.program.bulan_dan_tahun,
    };

    KegiatanAnggaranApi.create(finalValues)
      .then(() => {
        history.push('/anggaran/transaksi/kegiatan', {
          showAlert: true,
          alertText: 'Data berhasil ditambah!',
          alertVariant: 'primary'
        })
      })
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: `Tambah data gagal!`,
        });
      })
      .finally(() => {
        setShowAlert(true)
        setSubmitting(false)
      });
  };

  const totalSumberDaya = () => {
    let total = 0;

    dataSumberDaya.map((val) => {
      const subTotal = parseInt(val.harga_satuan) * parseInt(val.qty);
      total = total + subTotal;
    });

    return total;
  };

  const InfoItem = ({ title, text }) => (
    <tr>
      <td>{title}</td>
      <td className="pl-4 pr-2">:</td>
      <td>{text}</td>
    </tr>
  )

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>{/* Button Section */}</CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        onClose={() => setShowAlert(false)}
        variant={alertConfig.variant}
        text={alertConfig.text}
      />

      {/* Table Section */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
          data !== null ? (
            // Ada data
            <Card className="pb-3">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <b>Tambah Data</b>
                <Link to="/anggaran/transaksi/kegiatan">
                  <BackButton size="sm" />
                </Link>
              </Card.Header>
              <Card.Body>
                <Container>
                  {/* FormInput */}
                  <>
                    <Row>
                      <Col>
                        <table>
                          <InfoItem
                            title="Departemen"
                            text={data?.program?.nama_unit_organisasi ?? "-"}
                          />
                          <InfoItem
                            title="Penanggung Jawab"
                            text={data?.program?.nama_jabatan ?? "-"}
                          />
                          <InfoItem
                            title="Jenis Anggaran"
                            text={data?.program?.nama_jenis_anggaran ?? "-"}
                          />
                        </table>
                      </Col>
                      <Col>
                        <table>
                          <InfoItem
                            title="Kelompok Anggaran"
                            text={data?.program?.nama_kelompok_anggaran ?? "-"}
                          />
                          <InfoItem
                            title="Sub Kelompok Anggaran"
                            text={data?.program?.nama_sub_kelompok_anggaran ?? "-"}
                          />
                          <InfoItem
                            title="Nama Program"
                            text={data?.program?.nama_program ?? "-"}
                          />
                        </table>
                      </Col>
                    </Row>
                    <hr />
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={formValidationSchema}
                      onSubmit={formSubmitedHandler}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col lg="6">
                              <Input
                                label="Tanggal Kegiatan"
                                type="date"
                                name="tgl_input"
                                value={values.tgl_input}
                                onChange={(e) => {
                                  const value = e.target.value;

                                  setFieldValue("tgl_input", value);
                                  getNoKegiatan(value, setFieldValue);
                                }}
                                error={errors.tgl_input && touched.tgl_input && true}
                                errorText={errors.tgl_input}
                              />
                            </Col>
                            <Col lg="6">
                              <Input
                                label="No. Kegiatan"
                                type="text"
                                placeholder={
                                  isNomorLoading
                                    ? "Memuat . . . "
                                    : values.tgl_input
                                      ? kode
                                      : "Pilih tanggal terlebih dahulu"
                                }
                                readOnly={true}
                                name="no_kegiatan"
                                value={kode}
                                onChange={(e) => {
                                  setFieldValue("no_kegiatan", e.target.value);
                                }}
                                error={errors.no_kegiatan && touched.no_kegiatan && true}
                                errorText={errors.no_kegiatan}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <Input
                                label="Nama Kegiatan"
                                type="text"
                                placeholder="Masukan nama kegiatan"
                                name="nama_kegiatan"
                                value={values.nama_kegiatan}
                                onChange={(e) => {
                                  setFieldValue("nama_kegiatan", e.target.value);
                                  setDataForm({
                                    ...dataForm,
                                    nama_kegiatan: e.target.value,
                                  });
                                }}
                                error={errors.nama_kegiatan && touched.nama_kegiatan && true}
                                errorText={errors.nama_kegiatan}
                              />
                            </Col>
                            <Col lg="6">
                              <TextArea
                                label="Keterangan"
                                placeholder="Masukan keterangan"
                                name="deskripsi_kegiatan"
                                value={values.deskripsi_kegiatan}
                                onChange={(e) => {
                                  setFieldValue("deskripsi_kegiatan", e.target.value);
                                  setDataForm({
                                    ...dataForm,
                                    deskripsi_kegiatan: e.target.value,
                                  });
                                }}
                                error={
                                  errors.deskripsi_kegiatan && touched.deskripsi_kegiatan && true
                                }
                                errorText={errors.deskripsi_kegiatan}
                                rows={2}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <SelectSearch
                                label="Penanggung Jawab"
                                name="id_karyawan"
                                placeholder="Pilih penanggung jawab"
                                onChange={(val) => {
                                  setFieldValue("id_karyawan", val.value);
                                  // setFieldValue("nama_karyawan", val.value);
                                  setDataForm({
                                    ...dataForm,
                                    id_karyawan: val.value,
                                    nama_karyawan: val.label,
                                  });
                                }}
                                option={dataKaryawan.map((val) => {
                                  return {
                                    value: val.id_karyawan,
                                    label: val.nama_karyawan,
                                  };
                                })} //option harus berupa value dan label
                                defaultValue={{
                                  label: dataForm.nama_karyawan
                                    ? dataForm.nama_karyawan
                                    : "Pilih nama karyawan",
                                  value: dataForm.id_karyawan,
                                }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                                error={errors.id_karyawan && touched.id_karyawan && true}
                                errorText={errors.id_karyawan && touched.id_karyawan && true}
                              />
                            </Col>
                            <Col lg="3">
                              <DatePicker
                                showMonthYearPicker
                                showTwoColumnMonthYearPicker
                                dateFormat="MM/yyyy"
                                label="Periode Mulai"
                                selected={tanggalMulai}
                                onChange={(date) => {
                                  const newDate = DateConvert(new Date(date)).default;

                                  setTanggalMulai(date);
                                  setFieldValue("tgl_mulai", newDate);
                                }}
                                placeholderText="Pilih periode mulai"
                                error={errors.tgl_mulai && touched.tgl_mulai && true}
                                errorText={errors.tgl_mulai}
                              />
                            </Col>
                            <Col lg="3">
                              <DatePicker
                                showMonthYearPicker
                                showTwoColumnMonthYearPicker
                                dateFormat="MM/yyyy"
                                label="Periode Selesai"
                                selected={tanggalSelesai}
                                onChange={(date) => {
                                  const newDate = DateConvert(
                                    new Date(date.getFullYear(), date.getMonth() + 1, 0)
                                  ).default;
                                  setTanggalSelesai(date);
                                  setFieldValue("tgl_selesai", newDate);
                                }}
                                placeholderText="Pilih periode selesai"
                                error={errors.tgl_selesai && touched.tgl_selesai && true}
                                errorText={errors.tgl_selesai}
                              />
                            </Col>
                          </Row>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <CreateButton
                              size="sm"
                              title="Sumber Daya"
                              onClick={() => setIsCreateForm(true)}
                            />
                          </div>
                          <div className="py-2">
                            <b>Daftar Sumber Daya</b>
                          </div>
                          <Alert
                            show={createSumberDayaAlertConfig.show}
                            variant={createSumberDayaAlertConfig.variant}
                            text={createSumberDayaAlertConfig.text}
                            showCloseButton={true}
                            onClose={() => setCreateSumberDayaAlertConfig({
                              ...createSumberDayaAlertConfig,
                              show: false
                            })}
                          />
                          <CRUDLayout.Table>
                            <THead>
                              <Tr>
                                <ThFixed>No</ThFixed>
                                <ThFixed>Aksi</ThFixed>
                                <Th>Sumber Daya </Th>
                                <Th>Kategori Sumber Daya</Th>
                                <Th>Qty</Th>
                                <Th>Satuan</Th>
                                <Th>Harga Satuan</Th>
                                <Th>Sub Total</Th>
                              </Tr>
                            </THead>
                            <TBody>
                              {dataSumberDaya.map((val, index) => {
                                let subTotal;
                                subTotal = String(val.harga_satuan * val.qty);
                                return (
                                  <>
                                    <Tr key={index}>
                                      <TdFixed>{index + 1}</TdFixed>
                                      <TdFixed>
                                        <div className="d-flex justify-content-center">
                                          <DeleteButton
                                            onClick={() => {
                                              setDeleteData({
                                                ...val,
                                                _rowindex: index,
                                              });
                                              setIsDeleteData(true);
                                            }}
                                          />
                                        </div>
                                      </TdFixed>
                                      <Td>{val.item_sumber_daya}</Td>
                                      <Td>{val.nama_kategori_sumber_daya}</Td>
                                      <Td textRight>{val.qty}</Td>
                                      <Td>{val.satuan}</Td>
                                      <Td textRight>
                                        {RupiahConvert(val.harga_satuan.toString()).detail}
                                      </Td>
                                      <Td textRight>{RupiahConvert(subTotal.toString()).detail}</Td>
                                    </Tr>
                                  </>
                                );
                              })}
                              <tr>
                                <td colSpan={7} className="text-right">
                                  <strong>Total</strong>
                                </td>
                                <td className="text-right">
                                  <strong>
                                    {RupiahConvert(totalSumberDaya().toString()).detail}
                                  </strong>
                                </td>
                              </tr>
                            </TBody>
                          </CRUDLayout.Table>
                          <div className="d-flex justify-content-end my-4">
                            <ActionButton
                              type="submit"
                              variant="success"
                              text="Simpan"
                              className="px-3"
                              loading={isSubmitting}
                            >
                              <IoSaveOutline className="mr-2" />
                            </ActionButton>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                  {/* formInput */}
                </Container>
              </Card.Body>
            </Card>
          ) : (
            // Tidak ada data
            <DataStatus text="Tidak ada data" />
          )
      }

      <TambahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default AddKegiatan;
