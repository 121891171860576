import Axios from 'axios'
import JWTDecode from "jwt-decode"

const Services = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

Services.interceptors.request.use(config => {
  const { method } = config
  const auth_token = sessionStorage.getItem("token")

  const getHak = () => {
    if (auth_token) {
      const decode = JWTDecode(auth_token)
      return decode?.hak ?? []
    }
    return []
  }

  const checkIsGuest = (method) => {
    const isGuest = getHak().some((val) => val === "VISIT");
    return isGuest && Boolean(method === 'post' || method === 'put')
  }

  const token = checkIsGuest(method) ? null : "Bearer " + auth_token;

  config.headers = {
    'Authorization': token,
    'Content-Type': 'application/json'
  }

  return config
})

export default Services