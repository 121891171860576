import React, { useState, useEffect } from "react";
import { Card, Container, Col, Row } from "react-bootstrap";
import Axios from "axios";
import { useParams } from "react-router-dom";
import {
  BackButton,
  CRUDLayout,
  DataStatus,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
} from "../../../../components";
import { Link } from "react-router-dom";
import { KegiatanAnggaranApi } from "../../../../api";
import { DateConvert, RupiahConvert } from "../../../../utilities";

const DetailKegiatan = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);
  // configurasi alert
  // Fetch data
  const [data, setData] = useState([]);
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const { id_program, id_kegiatan } = useParams();
  // request data dari server
  const getData = () => {
    setIsLoading(true);

    // request data ke server
    Axios.all([
      KegiatanAnggaranApi.getFormEdit(id_program, id_kegiatan),
      KegiatanAnggaranApi.getKaryawan(),
    ])
      .then(
        Axios.spread((KegiatanAnggaran, KaryawanAnggaran) => {
          setData(KegiatanAnggaran.data.data);
          setDataKaryawan(KaryawanAnggaran.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Detail Kegiatan");

    // jalankan function request data ke server
    getData();
  }, [setNavbarTitle]);

  const DetailData = () => {
    const totalSumberDaya = () => {
      let total = 0;

      data.sumber_daya.map((val) => {
        const subTotal = parseInt(val.harga_satuan) * parseInt(val.qty);
        total = total + subTotal;
      });

      return total;
    };

    const InfoItem = ({ title, text }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{text}</td>
      </tr>
    )

    const ShowData = ({ title, text }) => (
      <div className="mb-2">
        <div className="text-secondary text-capitalize">
          <small>
            {title}
          </small>
        </div>
        <div className="text-dark">
          {text ? text : "-"}
        </div>
      </div>
    );

    return (
      <>
        <Row>
          <Col>
            <table>
              <InfoItem
                title="Departemen"
                text={data.program.nama_unit_organisasi}
              />
              <InfoItem
                title="Penanggung Jawab"
                text={data.program.nama_jabatan}
              />
              <InfoItem
                title="Jenis Anggaran"
                text={data.program.nama_jenis_anggaran}
              />
            </table>
          </Col>
          <Col>
            <table>
              <InfoItem
                title="Kelompok Anggaran"
                text={data.program.nama_kelompok_anggaran}
              />
              <InfoItem
                title="Sub Kelompok Anggaran"
                text={data.program.nama_sub_kelompok_anggaran}
              />
              <InfoItem
                title="Nama Program"
                text={data.program.nama_program}
              />
            </table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <ShowData
              title="Tanggal Kegiatan :"
              text={DateConvert(new Date(data?.kegiatan?.tgl_input)).detail}
              line={true}
            />
            <ShowData title="Nama Kegiatan :" text={data?.kegiatan?.nama_kegiatan} line={true} />
            <ShowData
              title="Penanggung Jawab :"
              text={
                data?.kegiatan?.id_karyawan
                  ? dataKaryawan.find((val) => val.id_karyawan === data?.kegiatan?.id_karyawan)?.nama_karyawan ?? "" : ""
              }
              line={true}
            />
            <ShowData
              title="Periode Mulai :"
              text={DateConvert(new Date(data?.kegiatan?.tgl_mulai)).detail}
              line={true}
            />
            <ShowData
              title="Periode Selesai :"
              text={DateConvert(new Date(data?.kegiatan?.tgl_selesai)).detail}
              line={true}
            />
          </Col>
          <Col lg={6}>
            <ShowData title="No. kegiatan :" text={data?.kegiatan?.no_kegiatan} line={true} />
            <ShowData title="Keterangan :" text={data?.kegiatan?.deskripsi_kegiatan} line={true} />
            <ShowData title="Catatan Approval :" text={data?.kegiatan?.catatan} line={true} />
          </Col>
        </Row>
        <hr />
        <div className="pb-2">
          <b>Daftar Sumber Daya</b>
        </div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Sumber Daya </Th>
              <Th>Kategori Anggaran</Th>
              <Th>Qty</Th>
              <Th>Satuan</Th>
              <Th>Harga Satuan</Th>
              <Th>Sub Total</Th>
            </Tr>
          </THead>
          <TBody>
            {data.sumber_daya.map((val, index) => {
              let subTotal;
              subTotal = String(val.harga_satuan * val.qty);
              return (
                <>
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <Td>{val.item_sumber_daya}</Td>
                    <Td>{val.nama_kategori_sumber_daya}</Td>
                    <Td textRight>{val.qty}</Td>
                    <Td>{val.satuan}</Td>
                    <Td textRight>{RupiahConvert(val.harga_satuan).detail}</Td>
                    <Td textRight>{RupiahConvert(subTotal).detail}</Td>
                  </Tr>
                </>
              );
            })}
            <tr>
              <td colSpan={6} className="text-right">
                <strong>Total</strong>
              </td>
              <td className="text-right">
                <strong>{RupiahConvert(totalSumberDaya().toString()).detail}</strong>
              </td>
            </tr>
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>{/* Button Section */}</CRUDLayout.Head>

      {/* Table Section */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
          data !== null ? (
            // Ada data
            <Card className="pb-3">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <b>Detail Data Kegiatan</b>
                <Link to="/anggaran/transaksi/kegiatan">
                  <BackButton size="sm" />
                </Link>
              </Card.Header>
              <Card.Body>
                <Container>
                  <DetailData />
                </Container>
              </Card.Body>
            </Card>
          ) : (
            // Tidak ada data
            <DataStatus text="Tidak ada data" />
          )
      }
    </CRUDLayout>
  );
};

export default DetailKegiatan;