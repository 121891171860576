import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
} from "react-icons/io5";

// IMPORT LOGO CORE
import Logo from "../assets/image/LogoSadhana.png";

// IMPORT PAGE ANGGARAN
import {
  // MASTER
  JenisAnggaran,
  KelompokAnggaran,
  SubkelompokAnggaran,
  KategoriAnggaran,
  // TRANSAKSI
  Program,

  // Kegiatan
  Kegiatan,
  AddKegiatan,
  ListProgram,
  EditKegiatan,
  DetailKegiatan,

  // ppa
  PPA,
  DetailPPA,
  CetakPPA,
  SumberDayaPPA,

  // realisasi ppa
  RealisasiPPA,
  ApprovedPPA,
  DetailRealisasiPPA,
  TambahRealisasiPPA,
  UbahRealisasiPPA,

  // Realisasi Anggaran
  // RealisasiAnggaran,
  // ListKegiatanRealisasi,
  // AddRealisasi,
  // DetailRealisasi
} from "../pages/Anggaran";

export default {
  LOGO: Logo,

  MODUL: "Anggaran",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["ANG"],
    },
    {
      text: "Master",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["ANG"],
      menu: [
        {
          text: "Jenis Anggaran",
          link: "/anggaran/master/jenis-anggaran",
          hak: ["ANG", "ANG_MAS_JEN"],
        },
        {
          text: "Kelompok Anggaran",
          link: "/anggaran/master/Kelompok-anggaran",
          hak: ["ANG", "ANG_MAS_KEL"],
        },
        {
          text: "Sub Kelompok Anggaran",
          link: "/anggaran/master/subKelompok-anggaran",
          hak: ["ANG", "ANG_MAS_SUB"],
        },
        {
          text: "Kategori Anggaran",
          link: "/anggaran/master/kategori-anggaran",
          hak: ["ANG", "ANG_MAS_KAT"],
        },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: ["ANG"],
      menu: [
        {
          text: "Program",
          link: "/anggaran/transaksi/program",
          hak: ["ANG", "ANG_TRN_PRO"],
        },
        {
          text: "Kegiatan",
          link: "/anggaran/transaksi/kegiatan",
          hak: ["ANG", "ANG_TRN_KEG"],
        },
        {
          text: "PPA",
          link: "/anggaran/transaksi/ppa",
          hak: ["ANG"],
        },
        // {
        //   text: 'Realisasi PPA',
        //   link: '/anggaran/transaksi/realisasi-ppa',
        //   hak: ['ANG'],
        // },
        // {
        //   text: 'Realisasi',
        //   link: '/anggaran/transaksi/realisasi',
        //   hak: ['ANG', 'ANG_TRN_REA'],
        // },
      ],
    },
    // {
    //   text: 'Profil',
    //   type: 'dropdown',
    //   icon: <IoPersonCircleOutline />,
    //   hak: ['ANG'],
    //   menu: [
    //     {
    //       text: 'Akun Saya',
    //       link: '/profil',
    //       hak: ['ANG'],
    //     },
    //     {
    //       text: 'Ubah Akun',
    //       link: '/profil/ubah',
    //       hak: ['ANG'],
    //     },
    //     {
    //       text: 'Ganti Password',
    //       link: '/profil/ganti-password',
    //       hak: ['ANG'],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    {
      exact: true,
      path: "/anggaran/master/jenis-anggaran",
      page: JenisAnggaran,
      hak: ["ANG", "ANG_MAS_JEN"],
    },
    {
      exact: true,
      path: "/anggaran/master/kelompok-anggaran",
      page: KelompokAnggaran,
      hak: ["ANG", "ANG_MAS_KEL"],
    },
    {
      exact: true,
      path: "/anggaran/master/subkelompok-anggaran",
      page: SubkelompokAnggaran,
      hak: ["ANG", "ANG_MAS_SUB"],
    },
    {
      exact: true,
      path: "/anggaran/master/kategori-anggaran",
      page: KategoriAnggaran,
      hak: ["ANG", "ANG_MAS_KAT"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/program",
      page: Program,
      hak: ["ANG", "ANG_TRN_PRO"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/kegiatan",
      page: Kegiatan,
      hak: ["ANG", "ANG_TRN_KEG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/kegiatan/list-program",
      page: ListProgram,
      hak: ["ANG", "ANG_TRN_KEG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/kegiatan/tambah-kegiatan/:id",
      page: AddKegiatan,
      hak: ["ANG", "ANG_TRN_KEG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/kegiatan/edit-kegiatan/:id_program/:id_kegiatan",
      page: EditKegiatan,
      hak: ["ANG", "ANG_TRN_KEG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/kegiatan/detail-kegiatan/:id_program/:id_kegiatan",
      page: DetailKegiatan,
      hak: ["ANG", "ANG_TRN_KEG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/ppa",
      page: PPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/ppa/detail/:id",
      page: DetailPPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/ppa/cetak/:id",
      page: CetakPPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/ppa/sumber-daya",
      page: SumberDayaPPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/realisasi-ppa",
      page: RealisasiPPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/realisasi-ppa/approved",
      page: ApprovedPPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/realisasi-ppa/detail/:id",
      page: DetailRealisasiPPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/realisasi-ppa/tambah/:id",
      page: TambahRealisasiPPA,
      hak: ["ANG"],
    },
    {
      exact: true,
      path: "/anggaran/transaksi/realisasi-ppa/ubah/:id",
      page: UbahRealisasiPPA,
      hak: ["ANG"],
    },

    // {
    //   exact: true,
    //   path: '/anggaran/transaksi/realisasi',
    //   page: RealisasiAnggaran,
    //   hak: ['ANG', 'ANG_TRN_REA']
    // },
    // {
    //   exact: true,
    //   path: '/anggaran/transaksi/realisasi/list-kegiatan',
    //   page: ListKegiatanRealisasi,
    //   hak: ['ANG', 'ANG_TRN_REA']
    // },
    // {
    //   exact: true,
    //   path: '/anggaran/transaksi/realisasi/tambah-realisasi/:id',
    //   page: AddRealisasi,
    //   hak: ['ANG', 'ANG_TRN_REA']
    // },
    // {
    //   exact: true,
    //   path: '/anggaran/transaksi/realisasi/detail/:id',
    //   page: DetailRealisasi,
    //   hak: ['ANG', 'ANG_TRN_REA']
    // },
  ],
};
