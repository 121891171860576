import React, {
  useState,
  useRef,
  useEffect
} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import { Card, Row, Col } from 'react-bootstrap'
import {
  ActionButton,
  BackButton,
  DataStatus,
  Alert,
} from '../../../../../components'
import {
  PPAApi
} from '../../../../../api'
import { DateConvert, RupiahConvert } from '../../../../../utilities'
import PrintPPA from '../PrintPPA'

const DetailPPA = ({ setNavbarTitle }) => {
  const { id } = useParams()
  const history = useHistory()
  const componentRef = useRef()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataPPA, setDataPPA] = useState({})
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [dataStatusConfig, setDataStatusConfig] = useState({
    loading: false,
    text: ''
  })

  // REACT TO PRINT
  const refPrint = useRef();
  // INIT USE REACT TO PRINT
  const reactToPrint = useReactToPrint({
    content: () => refPrint.current,
  });
  // ON CLICK HANDLE PRINT
  const handlePrint = () => {
    reactToPrint();
  }


  const getInitialData = () => {
    setIsPageLoading(true)
    setDataStatusConfig({
      loading: true,
      text: 'Memuat data . . .'
    })

    PPAApi.getDetail({
      id_ppa: id
    })
      .then(({ data }) => {
        setDataPPA(data?.data ?? {})
        setIsPageLoading(false)
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
        setDataStatusConfig({
          loading: false,
          text: 'Tidak ada data'
        })
      })
  }

  // COMPONENT
  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan PPA</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPPA?.persetujuan && dataPPA?.persetujuan?.length > 0 && dataPPA.persetujuan.map((val, index) => index !== 0 && (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER"
                      ? `Pemeriksa ${val.approval_level}`
                      : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const DetailData = () => {
    const InfoItem = ({ title, text }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{text}</td>
      </tr>
    )

    const ShowData = ({ title, text }) => (
      <div className="mb-2">
        <div className="text-secondary text-capitalize">
          <small>
            {title}
          </small>
        </div>
        <div className="text-dark">
          {text ? text : "-"}
        </div>
      </div>
    );

    return (
      <>
        <Row>
          <Col>
            <table>
              <InfoItem
                title="Program"
                text={dataPPA?.ppa?.nama_program ?? '-'}
              />
              <InfoItem
                title="Sumber Daya"
                text={dataPPA?.ppa?.sumber_daya ?? '-'}
              />
              <InfoItem
                title="Tipe Anggaran"
                text={dataPPA?.ppa?.tipe_anggaran ?? '-'}
              />
            </table>
          </Col>
          <Col>
            <table>
              <InfoItem
                title="Kegiatan"
                text={dataPPA?.ppa?.nama_kegiatan ?? '-'}
              />
              <InfoItem
                title="Bulan"
                text={(dataPPA?.ppa?.bulan_tahun) ? DateConvert(new Date(dataPPA?.ppa?.bulan_tahun)).detailMonth : '-'}
              />
            </table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <ShowData
              title="Tanggal PPA :"
              text={(dataPPA?.ppa?.tgl_ppa) ? DateConvert(new Date(dataPPA.ppa.tgl_ppa)).detail : '-'}
              line={true}
            />
            <ShowData title="Qty :" text={dataPPA?.ppa?.qty ?? '-'} line={true} />
            <ShowData
              title="Harga Satuan :"
              text={(dataPPA?.ppa?.harga_satuan) ? RupiahConvert(dataPPA.ppa.harga_satuan).detail : "-"}
              line={true}
            />
          </Col>
          <Col lg={6}>
            <ShowData title="No. PPA :" text={dataPPA?.ppa?.no_ppa ?? "-"} line={true} />
            <ShowData title="Satuan :" text={dataPPA?.ppa?.satuan ?? "-"} line={true} />
          </Col>
        </Row>
      </>
    );
  }

  useEffect(() => {
    setNavbarTitle('Detail PPA')
    getInitialData()

    return () => {

    }
  }, [])

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Data PPA</b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : (
          <>
            <DetailData />
            {dataPPA?.persetujuan?.length > 0 && <FormCard />}
            <div className="d-flex justify-content-end mt-2">
              <ActionButton className="btn btn-primary text-white" size="sm" text="Cetak PPA" onClick={handlePrint} />
            </div>
            <div style={{ display: 'none' }}>
              <PrintPPA dataPPA={dataPPA} ref={refPrint} />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default DetailPPA