import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import _ from "lodash";
import {
  CRUDLayout,
  InputSearch,
  ExportButton,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  CreateModalLarge,
  CreateModal,
  SelectSearch,
  UpdateModal,
  DeleteModal,
  Alert,
  Input,
  DatePicker,
  Pagination,
  Select,
  TextArea,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../components";

import {
  ProgramAnggaranApi,
  JenisAnggaranApi,
  KelompokAnggaranApi,
  SubkelompokAnggaranApi,
  UnitOrganisasiAnggaranApi,
  PenanggungJawabAnggaranApi,
} from "../../../api";
import { TableNumber } from "../../../utilities";
import { useIsGuest } from "hooks";

const Program = ({ setNavbarTitle }) => {
  const guest = useIsGuest();
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);
  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false);
  // menangani modal form ubah
  const [isUpdateform, setIsUpdateform] = useState(false);
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [dataJenisAnggaran, setDataJenisAnggaran] = useState([]);
  const [dataUnitOrganisasi, setDataUnitOrganisasi] = useState([]);
  const [dataKode, setDataKode] = useState("");
  const [dataKelompok, setDataKelompok] = useState([]);
  const [dataSubkelompok, setDataSubkelompok] = useState([]);
  const [dataPenanggungJawab, setDataPenanggungJawab] = useState([]);

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([]);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  //===============get data list ======================
  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setSearchKey("");

    // request data ke server
    Axios.all([
      ProgramAnggaranApi.getPage(page, dataLength, searchKey),
      JenisAnggaranApi.get(),
      UnitOrganisasiAnggaranApi.get(),
      // ProgramAnggaranApi.getKode(),
      KelompokAnggaranApi.get(),
      SubkelompokAnggaranApi.get(),
      PenanggungJawabAnggaranApi.get(),
    ])
      .then(
        Axios.spread(
          (
            programAnggaran,
            jenisAnggaran,
            unitOrganisasi,
            // kodeProgram,
            kelompok,
            subkelompok,
            penanggungJawab
          ) => {
            setData(programAnggaran.data.data);
            setTotalPage(programAnggaran.data.total_page);
            setDataCount(programAnggaran.data.data_count);
            setDataJenisAnggaran(jenisAnggaran.data.data);
            setDataUnitOrganisasi(unitOrganisasi.data.data);
            // setDataKode(kodeProgram.data.data);
            setDataKelompok(kelompok.data.data);
            setDataSubkelompok(subkelompok.data.data);
            setDataPenanggungJawab(penanggungJawab.data.data);
          }
        )
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  //===============end get data list =================

  //==============search data ================
  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    setIsLoading(true);

    // nilai dari form search
    const key = searchKey;

    // lakukan search data ke server pada kode dibawah [
    ProgramAnggaranApi.search(key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        setIsSearching(true);
        setIsLoading(false);
        setShowAlert(true);
      });
    // ]
  };
  //============end search data ===============

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Program");

    // jalankan function request data ke server
    getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // ==================Tabel================
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            {guest ? "" : <ThFixed>Aksi</ThFixed>}
            <ThFixed>No. Program</ThFixed>
            <Th>Nama Program</Th>
            <Th>Tahun</Th>
            <Th>Bulan</Th>
            <Th>Unit Organisasi</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => {
            let month = "";
            let year = "";
            let day = new Date(val.bulan_dan_tahun);
            month = day.toLocaleString("default", { month: "long" });
            year = day.getFullYear();

            return (
              <Tr key={index}>
                <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                {guest ? (
                  ""
                ) : (
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <UpdateButton
                        onClick={() => {
                          setUpdateData(val);
                          setIsUpdateform(true);
                        }}
                      />
                      <DeleteButton
                        onClick={() => {
                          setDeleteData(val);
                          setIsDeleteData(true);
                        }}
                      />
                    </div>
                  </TdFixed>
                )}
                <TdFixed>{val.no_program}</TdFixed>
                <Td>{val.nama_program}</Td>
                <TdFixed>{year}</TdFixed>
                <TdFixed>{month}</TdFixed>
                <Td>{val.nama_unit_organisasi}</Td>
              </Tr>
            );
          })}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      )}
    </>
  );
  //=============end tabel ============

  // ==========================Modal Tambah===================
  const TambahModal = () => {
    const [isJenisAnggaranChange, setIsJenisAnggaranChange] = useState(false);
    const [isKelompokAnggaranChange, setIsKelompokAnggaranChange] = useState(false);
    const [isUnitOrganisasiChange, setIsUnitOrganisasiChange] = useState(false);
    const [isJabatanChange, setIsJabatanChange] = useState(false);
    const [dataKelompokFiltered, setDataKelompokFiltered] = useState([]);
    const [dataSubKelompokFiltered, setDataSubKelompokFiltered] = useState([]);
    const [dataJabatanFiltered, setDataJabatanFiltered] = useState([]);
    const [kodeProgran, setKodeProgran] = useState("");

    const getKodeHandler = (value, setFieldValue) => {
      ProgramAnggaranApi.getKode(value)
        .then((res) => {
          setKodeProgran(res.data.data);
          setFieldValue("no_program", res.data.data);
        })
        .catch((err) => alert(err));
    };

    const onDataJenisAnggaranChange = (value) => {
      let kelompokFiltered = [];

      setDataKelompokFiltered([]);
      setDataSubKelompokFiltered([]);

      dataKelompok
        .filter((val) => val.id_jenis_anggaran === value)
        .map((val) => kelompokFiltered.push(val));

      setDataKelompokFiltered(kelompokFiltered);
    };

    const onDataKelompokAnggaranChange = (value) => {
      let subkelompokFiltered = [];

      setDataSubKelompokFiltered([]);

      dataSubkelompok
        .filter((val) => val.id_kelompok_anggaran === value)
        .map((val) => subkelompokFiltered.push(val));

      setDataSubKelompokFiltered(subkelompokFiltered);
    };
    const onDataUnitAnggaranChange = (value) => {
      let jabatanFiltered = [];

      setDataJabatanFiltered([]);

      dataPenanggungJawab
        .filter((val) => val.id_unit_organisasi === value)
        .map((val) => jabatanFiltered.push(val));

      setDataJabatanFiltered(jabatanFiltered);
    };

    useEffect(() => {}, []);

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      tgl_input: Yup.string().required("Masukan tanggal"),
      no_program: Yup.string().required("Masukan no program"),
      nama_program: Yup.string().required("Masukan nama program"),
      id_jabatan: Yup.string().required("Masukan nama jabatan"),
      id_unit_organisasi: Yup.string().required("Masukan nama unit organisasi"),
      id_sub_kelompok_anggaran: Yup.string().required("Masukan nama subkelompok anggaran"),
      id_jenis_anggaran: Yup.string().required("Masukan jenis anggaran"),
      id_kelompok_anggaran: Yup.string().required("Masukan kelompok anggaran"),
      deskripsi_program: Yup.string().required("Masukan deskripsi"),
    });

    const formInitialValues = {
      no_program: kodeProgran,
      nama_program: "",
      deskripsi_program: "",
      bulan_dan_tahun: new Date(),
      id_jabatan: "",
      id_jenis_anggaran: "",
      id_kelompok_anggaran: "",
      id_unit_organisasi: "",
      id_sub_kelompok_anggaran: "",
      tgl_input: "",
    };
    // request tambah data ke server
    const formSubmitHandler = (value) => {
      // value.bulan_dan_tahun = DateFormat(value.bulan_dan_tahun);
      ProgramAnggaranApi.create(value)
        .then(() => {
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch(() => {
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal!`,
          });
        })
        .finally(() => {
          // menampilkan alert
          setShowAlert(true);
          // menutup modal tambah
          setIsCreateForm(false);
          // request data baru ke server
          getData();
        });
    };

    return (
      <CreateModal
        size="xl"
        title="Program"
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Container>
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Input
                        label="Tanggal Program"
                        type="date"
                        name="tgl_input"
                        // placeholder="Masukan jenis anggaran"
                        value={values.tgl_input}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("tgl_input", value);
                          getKodeHandler(value, setFieldValue);
                        }}
                        error={errors.tgl_input && touched.tgl_input && true}
                        errorText={errors.tgl_input}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="No. Program"
                        type="text"
                        name="no_program"
                        readOnly
                        placeholder={
                          kodeProgran ? kodeProgran : "Pilih tanggal untuk menentukan kode"
                        }
                        value={kodeProgran}
                        onChange={handleChange}
                        error={errors.no_program && touched.no_program && true}
                        errorText={errors.no_program}
                      ></Input>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Input
                        label="Nama Program"
                        type="text"
                        name="nama_program"
                        placeholder="Masukan nama program"
                        value={values.nama_program}
                        onChange={handleChange}
                        error={errors.nama_program && touched.nama_program && true}
                        errorText={errors.nama_program}
                      ></Input>

                      {/* <Select
                        label="Unit organisasi"
                        name="id_unit_organisasi"
                        defaultValue={values.id_unit_organisasi}
                        onChange={(e) => {
                          const value = e.target.value;

                          value ? setIsUnitOrganisasiChange(true) : setIsUnitOrganisasiChange(false);
                          setIsJabatanChange(false);

                          setFieldValue("id_unit_organisasi", value);
                          setFieldValue("id_penanggung_jawab", "");
                          // setFieldValue("id_sub_kelompok_anggaran", "");
                          onDataUnitAnggaranChange(value, setFieldValue);
                        }}
                        error={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                        errorText={errors.id_unit_organisasi}
                      >
                        <option value="">Pilih unit organisasi</option>
                        {dataUnitOrganisasi
                          .filter((res) => res.is_hidden === false)
                          .map((res, index) => (
                            <option key={index} value={res.id_unit_organisasi}>
                              {res.nama_unit_organisasi}
                            </option>
                          ))}
                      </Select> */}
                      <SelectSearch
                        label="Unit Organisasi"
                        name="id_unit_organisasi"
                        onChange={(val) => {
                          val ? setIsUnitOrganisasiChange(true) : setIsUnitOrganisasiChange(false);
                          setIsJabatanChange(false);
                          setFieldValue("id_unit_organisasi", val.value);
                          setFieldValue("id_penanggung_jaawab", "");
                          onDataUnitAnggaranChange(val.value, setFieldValue);
                        }}
                        option={dataUnitOrganisasi
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_unit_organisasi,
                              label: val.nama_unit_organisasi,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label: dataUnitOrganisasi.nama_unit_organisasi
                            ? dataUnitOrganisasi.nama_unit_organisasi
                            : "Pilih unit organisasi",
                          value: dataUnitOrganisasi.id_unit_organisasi,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                        errorText={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                      />
                      {/* <Select
                        label="Penanggung jawab"
                        name="id_jabatan"
                        defaultValue={values.id_jabatan}
                        onChange={handleChange}
                        disabled={isUnitOrganisasiChange === false && true}
                        error={errors.id_jabatan && touched.id_jabatan && true}
                        errorText={errors.id_jabatan}
                      >
                        <option value="">Pilih subkelompok anggaran</option>
                        {dataJabatanFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val, index) => (
                            <option key={index} value={val.id_jabatan}>
                              {val.nama_jabatan}
                            </option>
                          ))}
                      </Select> */}
                      <SelectSearch
                        label="Penanggung Jawab"
                        name="id_jabatan"
                        isDisabled={isUnitOrganisasiChange === false && true}
                        onChange={(val) => {
                          setFieldValue("id_jabatan", val.value);
                        }}
                        option={dataJabatanFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_jabatan,
                              label: val.nama_jabatan,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label: dataJabatanFiltered.nama_unit_organisasi
                            ? dataJabatanFiltered.nama_unit_organisasi
                            : "Pilih penanggung jawab",
                          value: dataJabatanFiltered.id_unit_organisasi,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_jabatan && touched.id_jabatan && true}
                        errorText={errors.id_jabatan && touched.id_jabatan && true}
                      />

                      <TextArea
                        label="Deskripsi"
                        placeholder="Masukan deskripsi"
                        name="deskripsi_program"
                        value={values.deskripsi_program}
                        onChange={handleChange}
                        error={errors.deskripsi_program && touched.deskripsi_program && true}
                        errorText={errors.deskripsi_program}
                        rows={3}
                      />
                    </Col>
                    <Col>
                      <SelectSearch
                        label="Jenis Anggaran"
                        name="id_jenis_anggaran"
                        onChange={(e) => {
                          const value = e.value;

                          value ? setIsJenisAnggaranChange(true) : setIsJenisAnggaranChange(false);
                          setIsKelompokAnggaranChange(false);

                          setFieldValue("id_jenis_anggaran", value);
                          setFieldValue("id_kelompok_anggaran", "");
                          setFieldValue("id_sub_kelompok_anggaran", "");
                          onDataJenisAnggaranChange(value, setFieldValue);
                        }}
                        option={dataJenisAnggaran
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_jenis_anggaran,
                              label: val.nama_jenis_anggaran,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label: dataJenisAnggaran.nama_jenis_anggaran
                            ? dataJenisAnggaran.nama_jenis_anggaran
                            : "Pilih Jenis Anggaran",
                          value: dataJenisAnggaran.id_jenis_anggaran,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_jenis_anggaran && touched.id_jenis_anggaran && true}
                        errorText={errors.id_jenis_anggaran && errors.id_jenis_anggaran && true}
                      />
                      <SelectSearch
                        label="Kelompok Anggaran"
                        name="id_kelompok_anggaran"
                        isDisabled={isJenisAnggaranChange === false && true}
                        onChange={(e) => {
                          const value = e.value;

                          value
                            ? setIsKelompokAnggaranChange(true)
                            : setIsKelompokAnggaranChange(false);

                          setFieldValue("id_kelompok_anggaran", value);
                          setFieldValue("id_sub_kelompok_anggaran", "");
                          onDataKelompokAnggaranChange(value, setFieldValue);
                        }}
                        option={dataKelompokFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_kelompok_anggaran,
                              label: val.nama_kelompok_anggaran,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label: dataKelompok.nama_kelompok_anggaran
                            ? dataKelompok.nama_kelompok_anggaran
                            : "Pilih Kelompok Anggaran",
                          value: dataKelompok.id_kelompok_anggaran,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_kelompok_anggaran && touched.id_kelompok_anggaran && true}
                        errorText={
                          errors.id_kelompok_anggaran && errors.id_kelompok_anggaran && true
                        }
                      />
                      <SelectSearch
                        label="Sub Kelompok Anggaran"
                        name="id_sub_kelompok_anggaran"
                        isDisabled={isKelompokAnggaranChange === false && true}
                        onChange={(val) => {
                          setFieldValue("id_sub_kelompok_anggaran", val.value);
                        }}
                        option={dataSubKelompokFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_sub_kelompok_anggaran,
                              label: val.nama_sub_kelompok_anggaran,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label: dataSubkelompok.nama_sub_kelompok_anggaran
                            ? dataSubkelompok.nama_sub_kelompok_anggaran
                            : "Pilih Sub Kelompok Anggarah",
                          value: dataSubkelompok.id_sub_kelompok_anggaran,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={
                          errors.id_sub_kelompok_anggaran &&
                          touched.id_sub_kelompok_anggaran &&
                          true
                        }
                        errorText={
                          errors.id_sub_kelompok_anggaran &&
                          touched.id_sub_kelompok_anggaran &&
                          true
                        }
                      />

                      <DatePicker
                        label="Bulan Program"
                        selected={new Date(values.bulan_dan_tahun)}
                        name="bulan_dan_tahun"
                        defaultValue={values.bulan_dan_tahun}
                        onChange={(e) => {
                          setFieldValue("bulan_dan_tahun", e);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showFourColumnMonthYearPicker
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    className="px-4"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              </form>
            </Container>
          )}
        </Formik>
      </CreateModal>
    );
  };

  //=======================end modal tambah ================================
  //====================Modal Ubah ========================
  const UbahModal = () => {
    const [isJenisAnggaranChange, setIsJenisAnggaranChange] = useState(false);
    const [isKelompokAnggaranChange, setIsKelompokAnggaranChange] = useState(false);
    const [isUnitOrganisasiChange, setIsUnitOrganisasiChange] = useState(false);
    const [isJabatanChange, setIsJabatanChange] = useState(false);
    const [dataKelompokFiltered, setDataKelompokFiltered] = useState([]);
    const [dataSubKelompokFiltered, setDataSubKelompokFiltered] = useState([]);
    const [dataJabatanFiltered, setDataJabatanFiltered] = useState([]);

    const onDataJenisAnggaranChange = (value) => {
      let kelompokFiltered = [];

      setDataKelompokFiltered([]);
      setDataSubKelompokFiltered([]);

      dataKelompok
        .filter((val) => val.id_jenis_anggaran === value)
        .map((val) => kelompokFiltered.push(val));

      setDataKelompokFiltered(kelompokFiltered);
    };

    const onDataKelompokAnggaranChange = (value) => {
      let subkelompokFiltered = [];

      setDataSubKelompokFiltered([]);

      dataSubkelompok
        .filter((val) => val.id_kelompok_anggaran === value)
        .map((val) => subkelompokFiltered.push(val));

      setDataSubKelompokFiltered(subkelompokFiltered);
    };

    const onDataUnitAnggaranChange = (value) => {
      let jabatanFiltered = [];

      setDataJabatanFiltered([]);

      dataPenanggungJawab
        .filter((val) => val.id_unit_organisasi === value)
        .map((val) => jabatanFiltered.push(val));

      setDataJabatanFiltered(jabatanFiltered);
    };

    useEffect(() => {
      onDataJenisAnggaranChange(updateData.id_jenis_anggaran);
      onDataKelompokAnggaranChange(updateData.id_kelompok_anggaran);
      onDataUnitAnggaranChange(updateData.id_unit_organisasi);
    }, []);

    // nilai awal form
    const formInitialValues = {
      tgl_input: updateData.tgl_input,
      no_program: updateData.no_program,
      bulan_dan_tahun: updateData.bulan_dan_tahun,
      nama_program: updateData.nama_program,
      id_jenis_anggaran: updateData.id_jenis_anggaran,
      nama_kelompok_anggaran: updateData.nama_kelompok_anggaran,
      id_kelompok_anggaran: updateData.id_kelompok_anggaran,
      id_sub_kelompok_anggaran: updateData.id_sub_kelompok_anggaran,
      nama_sub_kelompok_anggaran: updateData.nama_sub_kelompok_anggaran,
      id_unit_organisasi: updateData.id_unit_organisasi,
      id_jabatan: updateData.id_jabatan,
      deskripsi_program: updateData.deskripsi_program,
    };

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      tgl_input: Yup.string().required("Masukan tanggal"),
      no_program: Yup.string().required("Masukan no program"),
      nama_program: Yup.string().required("Masukan nama program"),
      id_jabatan: Yup.string().required("Masukan nama jabatan"),
      id_unit_organisasi: Yup.string().required("Masukan nama unit organisasi"),
      id_sub_kelompok_anggaran: Yup.string().required("Masukan nama subkelompok anggaran"),
      id_jenis_anggaran: Yup.string().required("Masukan jenis anggaran"),
      id_kelompok_anggaran: Yup.string().required("Masukan kelompok anggaran"),
      deskripsi_program: Yup.string().required("Masukan deskripsi"),
    });

    // request ubah data ke server
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_program: updateData.id_program,
        ...values,
      };
      ProgramAnggaranApi.update(finalValues)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal!`,
          });
        })
        .finally(() => {
          setIsUpdateform(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <UpdateModal
        title="Program"
        size="xl"
        show={isUpdateform}
        onHide={() => setIsUpdateform(false)}
      >
        <Formik
          enableReinitialize={true}
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Container>
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Input
                        label="Tanggal Program"
                        type="date"
                        name="tgl_input"
                        // placeholder="Masukan jenis anggaran"
                        readOnly={true}
                        value={values.tgl_input}
                        onChange={handleChange}
                        error={errors.tgl_input && touched.tgl_input && true}
                        errorText={errors.tgl_input}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="No. Program"
                        type="text"
                        name="no_program"
                        readOnly
                        // placeholder="Masukan keterangan"
                        value={values.no_program}
                        onChange={handleChange}
                        error={errors.no_program && touched.no_program && true}
                        errorText={errors.no_program}
                      ></Input>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Input
                        label="Nama Program"
                        type="text"
                        name="nama_program"
                        placeholder="Masukan nama program"
                        value={values.nama_program}
                        onChange={handleChange}
                        error={errors.nama_program && touched.nama_program && true}
                        errorText={errors.nama_program}
                      ></Input>
                      {/* <Select
                        label="Unit organisasi"
                        name="id_unit_organisasi"
                        defaultValue={values.id_unit_organisasi}
                        onChange={(e) => {
                          const value = e.target.value;

                          value ? setIsUnitOrganisasiChange(true) : setIsUnitOrganisasiChange(false);
                          setIsJabatanChange(false);

                          setFieldValue("id_unit_organisasi", value);
                          setFieldValue("id_penanggung_jawab", "");
                          // setFieldValue("id_sub_kelompok_anggaran", "");
                          onDataUnitAnggaranChange(value, setFieldValue);
                        }}
                        error={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                        errorText={errors.id_unit_organisasi}
                      >
                        <option value="">Pilih unit organisasi</option>
                        {dataUnitOrganisasi
                          .filter((res) => res.is_hidden === false)
                          .map((res, index) => (
                            <option key={index} value={res.id_unit_organisasi}>
                              {res.nama_unit_organisasi}
                            </option>
                          ))}
                      </Select> */}
                      <SelectSearch
                        label="Unit organisasi"
                        name="id_unit_organisasi"
                        onChange={(val) => {
                          val ? setIsUnitOrganisasiChange(true) : setIsUnitOrganisasiChange(false);
                          setIsJabatanChange(false);

                          setFieldValue("id_unit_organisasi", val.value);
                          setFieldValue("id_penanggung_jaawab", "");
                          onDataUnitAnggaranChange(val.value, setFieldValue);
                        }}
                        option={dataUnitOrganisasi
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_unit_organisasi,
                              label: val.nama_unit_organisasi,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label: values.id_unit_organisasi
                            ? updateData.nama_unit_organisasi
                            : "Pilih unit organisasi",
                          value: updateData.id_unit_organisasi,
                        }}
                        error={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                        errorText={errors.id_unit_organisasi && touched.id_unit_organisasi && true}
                      />
                      {/* <Select
                        label="Penanggung jawab"
                        name="id_jabatan"
                        defaultValue={values.id_jabatan}
                        onChange={handleChange}
                        disabled={isUnitOrganisasiChange === false && true}
                        error={errors.id_jabatan && touched.id_jabatan && true}
                        errorText={errors.id_jabatan}
                      >
                        <option value={isUnitOrganisasiChange === false ? updateData.id_jabatan : ""}>
                          {isUnitOrganisasiChange === false
                            ? updateData.nama_jabatan
                            : "Pilih penanggung jawab"}
                        </option>
                        {dataJabatanFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val, index) => (
                            <option key={index} value={val.id_jabatan}>
                              {val.nama_jabatan}
                            </option>
                          ))}
                      </Select> */}
                      <SelectSearch
                        label="Penanggung jawab"
                        name="id_jabatan"
                        onChange={(val) => {
                          setFieldValue("id_jabatan", val.value);
                        }}
                        option={dataJabatanFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_jabatan,
                              label: val.nama_jabatan,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label: updateData.id_jabatan
                            ? updateData.nama_jabatan
                            : "Pilih penanggung jawab",
                          value: updateData.id_jabatan,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_jabatan && touched.id_jabatan && true}
                        errorText={errors.id_jabatan && touched.id_jabatan && true}
                      />

                      <TextArea
                        label="Deskripsi"
                        placeholder="Masukan deskripsi"
                        name="deskripsi_program"
                        value={values.deskripsi_program}
                        onChange={handleChange}
                        error={errors.deskripsi_program && touched.deskripsi_program && true}
                        errorText={errors.deskripsi_program}
                        rows={3}
                      />
                    </Col>
                    <Col>
                      <SelectSearch
                        label="Jenis Anggaran"
                        name="id_jenis_anggaran"
                        onChange={(e) => {
                          const value = e.value;

                          value ? setIsJenisAnggaranChange(true) : setIsJenisAnggaranChange(false);
                          setIsKelompokAnggaranChange(false);

                          setFieldValue("id_jenis_anggaran", value);
                          setFieldValue("id_kelompok_anggaran", "");
                          setFieldValue("id_sub_kelompok_anggaran", "");

                          onDataJenisAnggaranChange(value, setFieldValue);
                        }}
                        option={dataJenisAnggaran
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_jenis_anggaran,
                              label: val.nama_jenis_anggaran,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label:
                            values.id_jenis_anggaran !== ""
                              ? updateData.nama_jenis_anggaran
                                ? updateData.nama_jenis_anggaran
                                : "Pilih Jenis Anggran"
                              : "",
                          value: values.id_jenis_anggaran,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_jenis_anggaran && touched.id_jenis_anggaran && true}
                        errorText={errors.id_jenis_anggaran && touched.id_jenis_anggaran && true}
                      />
                      <SelectSearch
                        label="Kelompok Anggaran"
                        name="id_kelompok_anggaran"
                        onChange={(e) => {
                          const value = e.value;

                          value
                            ? setIsKelompokAnggaranChange(true)
                            : setIsKelompokAnggaranChange(false);

                          setFieldValue("id_kelompok_anggaran", value);
                          setFieldValue("id_sub_kelompok_anggaran", "");
                          onDataKelompokAnggaranChange(value, setFieldValue);
                        }}
                        option={dataKelompokFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_kelompok_anggaran,
                              label: val.nama_kelompok_anggaran,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label:
                            values.id_kelompok_anggaran !== ""
                              ? values.id_kelompok_anggaran
                                ? values.nama_kelompok_anggaran
                                : "Pilih Kelompok Anggran"
                              : "",
                          value: values.id_kelompok_anggaran,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_kelompok_anggaran && touched.id_kelompok_anggaran && true}
                        errorText={
                          errors.id_kelompok_anggaran && touched.id_kelompok_anggaran && true
                        }
                      />

                      <SelectSearch
                        label="Kelompok Anggaran"
                        name="id_sub_kelompok_anggaran"
                        onChange={(e) => {
                          setFieldValue("id_sub_kelompok_anggaran", e.value);
                        }}
                        option={dataSubKelompokFiltered
                          .filter((res) => res.is_hidden === false)
                          .map((val) => {
                            return {
                              value: val.id_sub_kelompok_anggaran,
                              label: val.nama_sub_kelompok_anggaran,
                            };
                          })} //option harus berupa value dan label
                        defaultValue={{
                          label:
                            values.id_sub_kelompok_anggaran !== ""
                              ? values.id_sub_kelompok_anggaran
                                ? values.nama_sub_kelompok_anggaran
                                : "Pilih Sub Kelompok Anggran"
                              : "",
                          value: values.id_sub_kelompok_anggaran,
                        }} // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={
                          errors.id_sub_kelompok_anggaran &&
                          touched.id_sub_kelompok_anggaran &&
                          true
                        }
                        errorText={
                          errors.id_sub_kelompok_anggaran &&
                          touched.id_sub_kelompok_anggaran &&
                          true
                        }
                      />
                      <DatePicker
                        label="Bulan Program"
                        selected={new Date(values.bulan_dan_tahun)}
                        name="bulan_dan_tahun"
                        defaultValue={values.bulan_dan_tahun}
                        onChange={(e) => {
                          setFieldValue("bulan_dan_tahun", e);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showFourColumnMonthYearPicker
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="px-4"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              </form>
            </Container>
          )}
        </Formik>
      </UpdateModal>
    );
  };
  //====================end Modal Ubah=================================

  //============= Modal Hapus =====================
  // Modal Hapus
  const HapusModal = () => {
    // id dari data yang ingin dihapus
    const idData = deleteData.id_program;
    const value = { id_program: idData };
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // request hapus data ke server

    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);
      ProgramAnggaranApi.delete(value)
        .then(() => {
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch(() => {
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!`,
          });
        })
        .finally(() => {
          // menampilkan alert
          setShowAlert(true);
          // menutup hapus modal
          setIsDeleteData(false);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title="Program"
      >
        <span>Nama Program : {deleteData.nama_program}</span>
      </DeleteModal>
    );
  };
  //====================end modal haus ===============

  //=================================return screen =========================

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        {/* Search Section */}
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table Section */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          // Ada data
          <Table />
        ) : (
          // Tidak ada data
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* Modal */}
      <TambahModal />
      {isUpdateform && <UbahModal />}
      <HapusModal />
    </CRUDLayout>
  );
};

export default Program;
