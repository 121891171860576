import React, {
  PureComponent
} from 'react'
import {
  Table
} from 'react-bootstrap'
import {
  RupiahConvert
} from '../../../../../utilities'

const PrintContent = ({ dataPPA }) => {
  const HeadSection = () => (
    <div className="d-flex flex-column align-items-center mb-4">
      <b>PT. MARDIKA GRIYA PRASTA</b>
      <b>PERSETUJUAN PRINSIP PENGADAAN BARANG DAN JASA</b>
      <b>SERTA PEMBAYARAN</b>
    </div>
  )

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <th>{title}</th>
        <td className="pl-5 pr-2">:</td>
        <td>{value}</td>
      </tr>
    )

    return (
      <table className="my-4">
        <tbody>
          <InfoItem title="Tanggal PPA" value={dataPPA?.ppa?.tgl_ppa ?? "-"} />
          <InfoItem title="No. PPA" value={dataPPA?.ppa?.no_ppa ?? "-"} />
          <InfoItem title="Dari" value={dataPPA?.ppa?.dari ?? "-"} />
          <InfoItem title="Program" value={dataPPA?.ppa?.nama_program ?? "-"} />
          <InfoItem title="Kegiatan" value={dataPPA?.ppa?.nama_kegiatan ?? "-"} />
          <InfoItem title="Sumber Daya" value={dataPPA?.ppa?.sumber_daya ?? "-"} />
          <InfoItem title="Mata Anggaran" value={dataPPA?.ppa?.tipe_anggaran ?? "-"} />
        </tbody>
      </table>
    )
  }

  const AnggaranSection = () => {
    const AnggaranItem = ({ title, value }) => (
      <tr>
        <th>{title}</th>
        <td className="pl-5 pr-2">:</td>
        <td className="text-right pl-2">{value}</td>
      </tr>
    )

    return (
      <div className="d-flex flex-column align-items-center my-4">
        <b className="mb-2">POS</b>
        <table>
          <tbody>
            <AnggaranItem title="Nilai" value={RupiahConvert(dataPPA?.mata_anggaran?.nominal_anggaran.toString() ?? '').detail} />
            <AnggaranItem title="Realisasi" value={RupiahConvert(dataPPA?.mata_anggaran?.nominal_terealisasi.toString() ?? '').detail} />
            <AnggaranItem title="Sisa" value={RupiahConvert(dataPPA?.mata_anggaran?.nominal_sisa.toString() ?? '').detail} />
            <AnggaranItem title="Jumlah Transaksi" value={RupiahConvert(dataPPA?.mata_anggaran?.jumlah_transaksi?.toString() ?? '').detail} />
          </tbody>
        </table>
      </div>
    )
  }

  const PersetujuanSection = () => {
    const HeadItem = ({ top, bottom }) => (
      <th
        className="text-center bg-light"
        style={{ width: '33.3%' }}
      >
        <div>{top}</div>
        <div>{bottom}</div>
      </th>
    )

    const InfoItem = ({ nama, tanggal }) => (
      <div style={{ fontSize: '14px' }}>
        <div className="d-flex pb-1">
          <div style={{ width: '50px' }}>Nama</div>
          <div className="pl-3 pr-2">:</div>
          <div>{nama}</div>
        </div>
        <div className="d-flex">
          <div style={{ width: '50px' }}>Tanggal</div>
          <div className="pl-3 pr-2">:</div>
          <div>{tanggal}</div>
        </div>
      </div>
    )

    return (
      <div className="mt-4 pb-3">
        <div className="text-center">
          <b>PERSETUJUAN PRINSIP</b>
        </div>
        <Table
          bordered
          className="mt-4"
        >
          <thead>
            <tr>
              <HeadItem top="Setuju Diadakan" bottom="(Approved)" />
              <HeadItem top="Diperiksa" bottom="(Checker)" />
              <HeadItem top="Pembuat" bottom="(Maker)" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ height: '150px' }}>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={dataPPA?.persetujuan?.ttd_approver ?? 'https://upload.wikimedia.org/wikipedia/commons/0/04/Tanda_tangan_bapak.png'}
                    width="100"
                  />
                </div>
              </td>
              <td style={{ height: '150px' }}>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={dataPPA?.persetujuan?.ttd_checker ?? 'https://upload.wikimedia.org/wikipedia/commons/0/04/Tanda_tangan_bapak.png'}
                    width="100"
                  />
                </div>
              </td>
              <td style={{ height: '150px' }}>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={dataPPA?.persetujuan?.ttd_maker ?? 'https://upload.wikimedia.org/wikipedia/commons/0/04/Tanda_tangan_bapak.png'}
                    width="100"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <InfoItem nama={dataPPA?.persetujuan?.approver ?? '-'} tanggal={dataPPA?.persetujuan?.tgl_approver ?? '-'} />
              </td>
              <td>
                <InfoItem nama={dataPPA?.persetujuan?.checker ?? '-'} tanggal={dataPPA?.persetujuan?.tgl_checker ?? '-'} />
              </td>
              <td>
                <InfoItem nama={dataPPA?.persetujuan?.maker ?? '-'} tanggal={dataPPA?.persetujuan?.tgl_maker ?? '-'} />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }

  const CatatanSection = () => {
    return (
      <div className="pt-3">
        <b>Catatan :</b>
        <ul>
          <li className="pt-3">
            PPA ini adalah PPA manual yang mengacu pada draft RKAP yang belum melalui RUPS RKAP 2021. Bila terjadi perubahan nilai setelah RUPS dilakukan, maka akan terdapat penyesuaian dalam sistem anggaran.
          </li>
          <li className="pt-3">
            PPA manual ini sebagai dasar proses pengadaan pekerjaan maupun pembayaran & akan diganti dengan PPA di sistem setelah RUPS & upload anggaran selesai dilakukan.
          </li>
        </ul>
      </div>
    )
  }

  return (
    <>
      <HeadSection />
      <hr />
      <InfoSection />
      <hr />
      <AnggaranSection />
      <hr />
      <PersetujuanSection />
      <hr />
      <CatatanSection />
    </>
  )
}

export default class PrintPPA extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="p-5 bg-white">
        <PrintContent
          dataPPA={this.props.dataPPA}
        />
      </div>
    )
  }
}
