import Services from "../../../services"

class PPAApi {
  getPage(params) {
    return Services.get("/ang/ppa/page", { params: params })
  }

  getKode(date) {
    return Services.get("/ang/ppa/no_baru", {
      params: {
        tanggal: date
      }
    })
  }

  getDetail(params) {
    return Services.get("/ang/ppa_realisasi/single", { params })
  }

  getDetailPPA(params) {
    return Services.get("/ang/ppa/single", { params })
  }

  getSumberDaya(params) {
    return Services.get("/ang/ppa/sumber_daya", { params })
  }

  createOrUpdate(values) {
    return Services.post("/ang/ppa", values)
  }
}

export default new PPAApi()
