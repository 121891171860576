import React, {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col,
  Table
} from 'react-bootstrap'
import {
  useHistory
} from 'react-router-dom'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ReadButton,
  UpdateButton,
  PrintButton,
  ActionButton,
  InputSearch,
  Input,
  Pagination,
  CreateModal,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td
} from '../../../../../components'
import {
  TableNumber,
  RupiahConvert
} from '../../../../../utilities'
import {
  PPAApi
} from '../../../../../api'

const ApprovedPPA = ({ setNavbarTitle }) => {
  const history = useHistory()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataApprovedPPA, setDataApprovedPPA] = useState([])
  const [processedData, setProcessedData] = useState({})
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    totalPage: '1',
    dataLength: '1',
    dataCount: '1'
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: ''
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'edit'
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PPAApi.getPage({
      page: paginationConfig.page,
      per_page: paginationConfig.total_page,
      q: searchConfig.key,
      kode_status: 'APP',
      status_ppa: 'OPEN'
    })
      .then((res) => {
        setDataApprovedPPA(res.data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res.data.data_count,
          dataLength: res.data.total_page
        })
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat'
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle('Realisasi Sumber Daya PPA')

    return () => {
      setIsPageLoading(true)
    }
  }, [paginationConfig.page, paginationConfig.dataLength, searchConfig.key])

  const PageContent = () => {
    return (
      <>
        <Table
          className="bg-white shadow-sm"
          bordered
          responsive
        >
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Program</Th>
              <Th>Kegiatan</Th>
              <Th>No. PPA</Th>
              <Th>Sumber Daya</Th>
              <Th>Nominal</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataApprovedPPA.map((val, index) => (
              <Tr key={index}>
                <Td>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
                <Td>{val.nama_program}</Td>
                <Td>{val.nama_kegiatan}</Td>
                <Td>{val.no_ppa}</Td>
                <Td>{val.item_sumber_daya}</Td>
                <Td>{RupiahConvert((val.harga_satuan * val.qty).toString()).detail}</Td>
                <TdFixed>
                  <ActionButton
                    size="sm"
                    text="Realisasi"
                    onClick={() => history.push('/anggaran/transaksi/realisasi-ppa/tambah/' + val.id_ppa)}
                  />
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
          dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) => setPaginationConfig({
            ...paginationConfig,
            page: selected + 1
          })}
          onDataLengthChange={(e) => setPaginationConfig({
            ...paginationConfig,
            page: 1,
            dataLength: e.target.value
          })}
        />
      </>
    )
  }

  const PageModal = () => {
    const ModalForm = () => {
      const InfoForm = () => {
        const InfoItem = ({ title, value }) => (
          <tr>
            <td><small>{title}</small></td>
            <td className="pl-3 pr-1"><small>:</small></td>
            <td><small>{value}</small></td>
          </tr>
        )

        return (
          <table>
            <tbody>
              <InfoItem
                title="Program"
                value={processedData.nama_program}
              />
              <InfoItem
                title="Kegiatan"
                value={processedData.nama_kegiatan_sumber_daya}
              />
              <InfoItem
                title="Sumber Daya"
                value={processedData.nama_sumber_daya}
              />
              <InfoItem
                title="Bulan"
                value="Januari 2020 (Test)"
              />
              <InfoItem
                title="Tipe Anggaran"
                value="001-Test (Test)"
              />
            </tbody>
          </table>
        )
      }

      return (
        <>
          <InfoForm />
          <hr />
          <Formik>
            {() => (
              <form className="mt-3">
                <Input
                  type="date"
                  label="Tanggal PPA"
                />
                <Input
                  type="text"
                  label="No. PPA"
                  readOnly
                />
                <Row>
                  <Col>
                    <Input
                      type="number"
                      label="Qty"
                    />
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      label="Satuan"
                    />
                  </Col>
                </Row>
                <Input
                  type="text"
                  label="Harga Satuan"
                />
                <Input
                  type="text"
                  label="Total Harga"
                  readOnly
                />
                <div className="d-flex justify-content-end pt-3">
                  <ActionButton
                    variant="primary"
                    text="Simpan Data"
                  />
                </div>
              </form>
            )}
          </Formik>
        </>
      )
    }

    if (modalConfig.type === 'edit') {
      return (
        <CreateModal
          show={modalConfig.show}
          onHide={() => setModalConfig({
            ...modalConfig,
            show: false
          })}
        >
          <ModalForm />
        </CreateModal>
      )
    }
  }

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari realisasi sumber daya PPA"
              onChange={e => setSearchConfig({
                ...searchConfig,
                key: e.target.value
              })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({
          ...alertConfig,
          show: false
        })}
      />

      {/* content section */}
      {isPageLoading
        ? <DataStatus
          loading={true}
          text="Memuat data . . ."
        />
        : dataApprovedPPA
          ? dataApprovedPPA.length > 0
            ? <PageContent />
            : <DataStatus text="Tidak ada data" />
          : <DataStatus text="Tidak ada data" />
      }

      {/* Modal */}
      <PageModal />
    </CRUDLayout>
  )
}

export default ApprovedPPA
