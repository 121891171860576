import Services from "../../../services";

class ProgramAnggaranApi {
  get() {
    return Services.get("/ang/program");
  }

  getOne(id) {
    return Services.get("/ang/program/single/?id_program=" + id);
  }

  getPage(page, dataLength, key) {
    return Services.get(`/ang/program/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  getKode(value) {
    return Services.get("/ang/program/no_baru?tanggal=" + value);
  }

  getJenis(id) {
    return Services.get("/ang/jenis_anggaran" + id);
  }

  getKelompok(id) {
    return Services.get("/ang/kelompok_anggaran" + id);
  }

  getSubKelompok(id) {
    return Services.get("/ang/sub_kelompok_anggaran" + id);
  }

  create(value) {
    return Services.post("/ang/program", value);
  }

  update(value) {
    return Services.put("/ang/program", value);
  }

  delete(value) {
    return Services.post("/ang/program/delete", value);
  }
}

export default new ProgramAnggaranApi();
